import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import ProductListItem from "../components/ProductListItem";
import SEO from "../components/seo";
import homeStyles from "../styles/home.module.css";
import "../styles/home.css";
import ArrowDecor from "../components/home/ArrowDecor";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomePageTemplate = ({ data, pageContext, location }) => {
  const page = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const { previous, next } = pageContext;

  const products = data.allMarkdownRemark.edges;
  console.log("productz", products, page);

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "site-layout--landing bg-image bg-fixed",
        }}
      />
      <div id="particles-background" class="vertical-centered-box"></div>
      <div id="particles-foreground" class="vertical-centered-box"></div>
      <Header />

      <main class="site-content text-center" id="wrapper">
        <div class="site-content__center">
          <h1 class="text-white landing-title">
            <span class="subtitle landing-subtitle">Get Your Game On</span>
            Apex Warrior
          </h1>
          <Link to="/products" className="btn btn-primary btn-lg btn--landing">
            <span>Start Browsing!</span>
          </Link>
        </div>
      </main>
      <Footer
      // info={data.markdownRemark.frontmatter}
      // logoSrc={data.layout.frontmatter.logo}
      />
      <ArrowDecor />
    </Layout>
  );
};

export default HomePageTemplate;

const InstaFeed = () => {
  return (
    <div id="instagramFeed" className="full-width">
      <div class="instagram_gallery">
        <a
          href="https://www.instagram.com/p/Bh-P3IoDxyB"
          rel="noopener"
          target="_blank"
        >
          <img src="..." alt="instagram instagram image 0" />
        </a>
        ...
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        templateKey
        logo
        companyName
        waNum
        waMessage
        email
        instaStoreUrl
        companyAddress
        messageParagraph {
          messageBody
          messageHeader
        }
        testimonials {
          image
          text
          name
        }
        brands {
          image
          brandUrl
        }
        heroSlider {
          slide1 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
          slide2 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
          slide3 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
        }
        id
        videoUrl
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "product" }, isHighlight: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            price
            date(locale: "id")
            thumbnail
            images {
              image
            }
            tag
            redLabel
            blackLabel
            tokpedUrl
            instaUrl
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
